$width: 70px;
$heigh: 40px;

.langchanger {
  width: $width;
  height: $heigh;
  margin-right: 5px;

  &__block {
    width: $width;
    height: $heigh;
    z-index: 50;
  }

  &__flag_case {
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
    display: flex;
  }

  &__flag {
    width: $width;
    height: $heigh;
    position: absolute;
    z-index: 4;
  }

  &__symbol {
    height: 95%;
    margin: auto;
    z-index: 5;
  }
}

@media screen and (max-width: 850px) {

  .langchanger {
    &__block {
      position: inherit;
    }
  }
}