.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;

  &__img {
    max-height: 100%;
    width: 100%;
    border: 1px azure solid;
    border-radius: 10px;
  }

  &__crop_case {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  &__crop_button {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    background-color: transparent;
    color: azure;
    border: 1px solid azure;
    font-size: 16pt;
  }
  
  &__message {
    position: absolute;
    color: white;
    background-color: black;
    border: 1px solid azure;
    border-radius: 10px;
    padding: 10px;
    opacity: 0.6;
  }
  
  &__drop_case {
    position: absolute;
    z-index: 51;
  }
  
  &__drop_fon {
    position: absolute;
    background-color: rgba(104, 96, 96, 0.555);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  &__drop_effect {
    border: 4px solid rgba(53, 50, 50, 0.733);
    border-radius: 20px;
    animation: extension linear infinite 1s;
  }
}

.drop_avatar {
  width: 350px;
  height: 350px;
}


.drop_avatar:hover > .avatar__message {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .drop_avatar {
    width: 90vw;
    height: 90vw;
    min-height: 90vw;
  }
}