.multfilm {

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    color: white;
    text-decoration: none;
  }

  &__list:focus {
    background-color:rgba(160, 27, 67, 0.2);
  }

  &__list_logo:hover {
    max-width: 95%;
    max-height: 95%;
  }

  &__image_block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89%;
  }

  &__list_logo {
    max-width: 90%;
    max-height: 90%;
  }

  &__watched {
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(../../../../assets/decorations/Stamp_seal.png) no-repeat center/95%;
    background-color: rgba(0, 0, 0, 0.05);
    transform: rotate(-30deg); 
  }

  &__viewed {
    background:rgba(160, 27, 67, 0.35);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__unviewed {
    background:rgba(160, 27, 67, 0.35);
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 0;
    animation: wipe 4s linear forwards;
  }

  &__opened {
    position: absolute;
    animation: disappearance 2s linear forwards;
    z-index: 50;
  }
}

.pour {
  width: 25px;
  margin-left: -12.5px;
  position: absolute;
  left: 50%;
  bottom: 0;
  top: 0;
  z-index: -1;
  animation: pourAction linear forwards 5s;
}

@keyframes pourAction {
  0% {
    background:rgba(160, 27, 67, 0.35);
    transform: translateY(-100%);
  }
  20% {
    transform: translateY(0);
    background:rgba(160, 27, 67, 0.35);
  }
  100% {
    transform: translateY(-100%);
    background:rgba(160, 27, 67, 0.35);
  }
}

@keyframes disappearance {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes wipe {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@media screen and (max-width: 850px) {
  .multfilms__image_block {
    margin-top: 10px;
  }
}