.profile {
  width: 100%;
  display: flex;
  justify-content: center;

  &__case {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@keyframes extension {
  0% {
    width: 10px;
    height: 10px;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}