.multfilm_page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;
  }

  &__title_img_container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title_img {
    height: 200px;
  }

  &__title_container {
    width: 40%;
  }

  &__title {
    text-align: center;
    font-size: 18pt;
    font-weight: bold;
  }

  &__description {
    text-indent: 30px;
  }

  &__button_to-rules {
    margin-top: auto;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 850px) {
  .multfilm_page {
    &__container {
      display: block;
      width: 90%;
    }

    &__title_img_container {
      width: 100%;
    }

    &__title_container {
      width: 100%;
    }
  }
}