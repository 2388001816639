.auth_menu {
  display: flex;
  align-items: center;

  &__profile {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
  }

  &__avatar {
    height: 50px;
    margin-right: 5px;
  }

  &__delimiter {
    margin-left: 5px;
    margin-right: 5px;
  }

  &__button {
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.85;
  }

  &__button:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 850px) {
  .auth_menu {
    &__button {
      opacity: 1;
    }
  }
}