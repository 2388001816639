.user-offer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18pt;

  &__opened {
    min-height: 550px;
  }
}

@media screen and (max-width: 600px) {
  .user-offer {
    &__opened {
      min-height: 650px;
    }
  }
}