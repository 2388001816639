.survey {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  width: 100%;
  min-height: 60vh;

  &__question {
    font-size: 16pt;
    margin-bottom: 10px;
  }

  &__image {
    height: 400px;
  }
  
  &__answers {
    margin: 10px 10px 25px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  &__answer {
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px;
  }

  &__answer:hover {
    color: #010522;
    background-color: azure;
    transition: 0.25s;
  }

  &__answer_button {
    visibility: hidden;
    position: absolute;
    left: 0;
  }

  &__lazy {
    max-height: 65vh;
  }
}

@media screen and (max-width: 850px) {
  .survey {
    
    &__form {
      margin-top: 20px;
    }

    &__image {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .survey {
    &__image {
      height: auto;
      max-height: 400px;
      max-width: 90%;
      margin: 0 10px;
    }
  }
}