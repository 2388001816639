.button {
  cursor: url('../components/assets/decorations/Pointer.png'), auto;

  &__general {
    padding: 15px;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 16pt;
    border: 1px solid #010522;
  }

  &__general:hover {
    color: rgb(213, 219, 226);
    background-color: #010522;
    transition: 0.25s;
    border-color: azure;
  }
}