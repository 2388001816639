.aboutProject {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text p {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .aboutProject {

  }
}