.characters {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    width: 80%;
  }

  &__block {
    margin: 0 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid white;
    border-radius: 10px;
  }

  &__name {
    margin-top: 5px;
  }

  &__avatar {
    height: 50px;
    margin: 5px;
  }
  
  &__info {
    overflow: hidden;
    margin-top: 10px;
    animation: shiftGlobal linear forwards 0.4s;
  }

  &__big_slider &__info {
    animation: shiftGlobalBig linear forwards 0.4s;
    width: calc(300% + 100px);
  }
  &__increased_slider &__info {
    width: calc(200% + 40px);
  }
  &__average_slider &__info {
    width: calc(150% + 30px);
  }
  &__decreased_slider &__info {
    width: calc(100% + 20px);
  }
  &__small_slider &__info {
    width: 50%;
  }

  .slick-slide &__info {
    display: none;
  }

  .slick-current &__info {
    display: block;
  }
  .slick-arrow {
    top: 45px;
  }

  &__info_container {
    display: flex;
    animation: emergence linear forwards 0.2s;
  }

  &__full_length {
    max-width: 250px;
    max-height: 375px;
  }

  &__text_container {
    overflow: hidden;
  }

  &__text {
    padding-left: 40px;
    box-sizing: border-box;
    animation: shiftPartial linear forwards 0.4s;
  }

  &__name {
    text-align: center;
    font-weight: bold;
  }

  &__text_info {
    text-align: justify;
    text-indent: 30px;
  }

  &__text_info p {
    margin: 5px 0px;
  }
}

@keyframes shiftGlobalBig {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(33%);
  }
} 

@keyframes shiftGlobal {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}

@keyframes shiftPartial {
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes emergence {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1400px) {
  .characters {
    &__increased_slider &__info {
      width: calc(300% + 80px);
    }
    &__average_slider &__info {
      width: calc(200% + 60px);
      animation: shiftAvirage linear forwards 0.4s;
    }
    &__decreased_slider &__info {
      width: calc(150% + 20px);
    }
    &__small_slider &__info {
      width: 50%;
    }
  }
  
  @keyframes shiftGlobal {
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(16.65%);
    }
  }
  @keyframes shiftAvirage {
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(25%);
    }
  }
}

@media screen and (max-width: 600px) {
  .characters {
    &__small_slider &__info {
      width: 110%;
      // animation: none;
    }

    // &__info {
    //   animation: shiftGlobal linear forwards 0.4s;
    // }  

    &__full_length {
      max-width: 100px;
      max-height: 350px;
      animation: none;
    }

    // &__info_container {
    //   animation: emergence linear forwards 0.4s;
    // }

    &__text {
      padding-left: 5px;
    }

    &__text_info {
      font-size: 12pt;
      text-indent: 10px;
    }
  }

  // @keyframes emergence {
  //   0% {
  //     transform: translateY(-100%);
  //   }
  
  //   50% {
  //     transform: translateY(0);
  //   }

  //   100% {
  //     transform: translateX(-5%);
  //   }
  // }
  // @keyframes shiftMobile {
  //   50% {
  //     transform: translateX(0);
  //   }
  //   100% {
  //     transform: translateX(-10%);
  //   }
  // }
  @keyframes shiftGlobal {
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-5%);
    }
  }
}