.header {
  height: 60px;
  display: flex;
  margin: 10px 25px 30px 25px;
  font-size: 18pt;
  font-weight: bold;

  &__h1 {
    visibility: hidden;
    position: absolute;
  }
};


@media screen and (max-width: 850px) {
  .header {
    margin: 0;
  }
}