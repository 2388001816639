.main {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;

  &__h2 {
    font-size: 24pt;
    margin-top: 0px;
  }

  &__picture {
    max-height: 70vh;
    max-width: 90%;
  }
}