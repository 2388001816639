.multoffer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 20px;
    width: 70%;
    text-align: center;
  }

  &__text {
    margin-bottom: 15px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__case {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    align-items: center;
  }

  &__replay {
    height: 50px;
    font-size: 18px;
  }

  &__replay_short {
    width: 25%;
  }
  &__replay_long {
    width: 65%;
    min-height: 200px;
  }
  &__error_border {
    border: 1.5px solid red;
  }
}