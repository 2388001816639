.greet {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  text-align: center;

  &__answers {
    display: flex;
  }

  &__answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:rgba(160, 27, 67, 0.35);
    border-radius: 25px;
    margin: 10px 10px 0px 0px;
  }

  &__answer_carcas {
    height: 275px;
    width: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__answer_image {
    max-width: 90%;
    max-height: 90%;
  }

  &__text {
    margin-bottom: 5px;
  }

  &__answer:hover {
    background-color:rgba(160, 27, 67, 0.7);
  }

  &__answer:hover &__answer_image {
    max-width: 95%;
    max-height: 95%;
  }

  &__warning {
    margin: 50px;
  }
}

@media screen and (max-width: 850px) {
  .greet {

    &__answers {
      flex-direction: column;
    }

    &__answer {
      margin: 0px 0px 10px 0px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__answer_carcas {
      height: 150px;
      width: 150px;
    }
  }
}

