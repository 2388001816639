.functionality {
  &__menu {
    width: 100%;
  }

  &__menu_list {
    display: flex;
  }

  &__list {
    display: flex;
  };

  &__button {
    padding: 15px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
  }
  &__link {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &__button:hover {
    color: rgb(31, 28, 28);
    background-color: rgb(213, 219, 226);
    transition: 0.25s;
  }

  &__right_side {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }
  
  &__burger_menu {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .functionality {

    &__menu {
      display: none;
    }
    
    &__menu_mobile {
      position: absolute;
      width: 100%;
      background-color: rgba(139, 0, 0, 0.808);
      z-index: 100;
    }

    &__list {
      flex-direction: column;
      align-items: center;
    }

    &__menu_list {
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      gap: 10px;
    }

    &__right_side {
      margin: 0px;
      flex-direction: column;
      align-items: center;
    }
    
    &__burger_menu {
      display: flex;
      margin-left: auto;
      margin-right: 10px;
    }
  }
}
