.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;

  &__case {
    display: flex;
    justify-content: space-between;
    width: 1200px;
  }

  &__links {
    display: flex;
    gap: 15px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 10px
  }

  &__link {
    opacity: 0.85;
  }

  &__link img {
    width: 25px;
  }

  &__link:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    padding: 30px;
    margin: 0;
    box-sizing: border-box;

    &__case {
      width: 100%;
    }
  }
}

@media screen and (max-width: 850px) {
  .footer {
    &__link {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    &__case {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}