.multChain {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;

  &__main {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }
}
.multfilm {
  &__locked {
    background: url(../../../assets/decorations/Lock.png) no-repeat center/100% rgba(160, 27, 67, 0.35);
  }

  &__block {
    height: 290px;
    width: 280px;
    position: relative;
    margin-bottom: 10px;
    border-radius: 100%;
    overflow: hidden;
    background-color:rgba(160, 27, 67, 0.35);
  }
}

@media screen and (max-width: 850px) {
  .multfilms {
    flex-direction: row;

    &__category {
      width: 30%;
    }
  }
  .multfilm {
    &__block {
      width: 30vw;
      height: 30vw;
    }
  }
}