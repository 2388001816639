.mult-changer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16pt;

  &__title {
    margin-bottom: 0;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 500px;
  }

  &__text_input {
    width: 100%;
    border: 1px solid azure;
    outline: none;
    font-size: 14pt;
    z-index: 20;
  }
  &__text_input::-webkit-outer-spin-button, 
  &__text_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__field {
    display: flex;
    justify-content: center;
  }
  &__field_id {
    width: 50px;
  }  
  &__field_name {
    width: 150px;
  }  
  &__field_level {
    width: 75px;
  }  
  &__field_serial {
    width: 75px;
  }

  &__submit {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-weight: bold;
    background-color: transparent;
    color: azure;
    border-color: azure;
    font-size: 16pt;
  }

  &__submit:hover {
    background-color: azure;
    transition: 0.25s;
    color:#010522;
  }

  &__error {
    border: 1px solid rgb(197, 1, 1);
    outline: none;
    box-sizing: border-box;
    background-color: rgb(247, 222, 222);
  }

  &__leveller {
    width: 25px;
  }

  &__section_title_container {
    margin-top: 25px;
  }

  &__section_title {
    margin-bottom: 10px;
    display: inline-block;
  }

  &__add-button {
    padding: 5px 10px;
    background-color: transparent;
    color: azure;
    border-color: azure;
    border-radius: 5px;
    font-size: 14pt;
  }

  &__add-button:hover {
    background-color: azure;
    transition: 0.25s;
    color:#010522;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  &__second-leveller {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .mult-changer {
    &__row {
      width: 90vw;
    }

    &__field_name {
      width: 140px;
    }  
  }
}