@import 'scssStyles/text.scss';
@import 'scssStyles/buttons.scss';
@import 'scssStyles/divs.scss';

body {
  margin: 0;
  background-image: url(./components/assets/fon.png);
  cursor: url('components/assets/decorations/Cursor.png'), auto;
  background-color: #010522;
  color: white;
  font-size: 14pt;
  font-family: 'Roboto', sans-serif;

  a, label, button, .close {
    cursor: url('components/assets/decorations/Pointer.png'), auto;
  }

  a {
    text-decoration: none;
    color: azure;
  }
}

input[type=file] {
  visibility: hidden;
  position: absolute;
}

textarea {
  border-radius: 5px;
}