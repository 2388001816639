.auth {
  width: 100%;
  height: 100%;
  background-color: rgba(19, 19, 53, 0.308);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position:fixed;

  &__form {
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:rgba(19, 19, 53, 0.911);
    padding: 20px 30px;
    border-radius: 20px;
    font-size: 14pt;
    border: 1px solid azure;
  }

  &__close_form {
    width: 100%;
  }

  &__close_button {
    opacity: 0.85;
    float: right;
  }

  &__close_button:hover {
    opacity: 1;
  }

  &__form_name {
    font-size: 20pt;
  }
  
  &__inputs{
    display: flex;
    justify-content: space-around;
    width: 300px;
    flex-wrap: wrap;
    margin: 20px 0px 20px 0px;
    gap: 10px;
  }

  &__input_case {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    z-index: 20;
  }

  &__text{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__error_case {
    position: absolute;
    z-index: 19;
  }

  &__error_block {
    background-color: rgb(146, 54, 54);
    position: relative;
    height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    right: calc(100% + 15px);
    padding: 0px 10px;
  }

  &__error_block::after {
    content: ''; 
    position: absolute;
    right: -20px; top: 6.5px;
    border: 10px solid transparent;
    border-left: 10px solid rgb(146, 54, 54);
  }

  &__error_text {
    font-size: 10pt;
  }

  &__error_border {
    border: 1px solid rgb(197, 1, 1);
    outline: none;
  }

  &__global_error {
    color: red;
    font-size: 14pt;
  }

  &__form_changer {
    opacity: 0.85;
  }

  &__form_changer:hover {
    opacity: 1;
  }

  &__submit {
    font-weight: bold;
    font-size: 16pt;
    background-color: transparent;
    color: azure;
    border-color: azure;
    margin-bottom: 10px;
  }

  &__submit:hover {
    background-color: azure;
    color: #010522;
  }

  &__checkbox_case {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__checkbox {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 850px) {
  .auth {
    z-index: 101;
  }
}

@media screen and (max-width: 400px) {
  .auth {
    &__form {
      padding: 20px 19px;
    }
  }
}