.questoffer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 20px;
    width: 70%;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__case {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
    gap: 10px;
  }

  &__replay {
    font-size: 18px;
    min-width: 150px;
    height: 56px;
    padding: 2px;
    border: 1px solid black;
    box-sizing: border-box;
  }

  &__replay_short {
    width: 12.5%;
  }
  &__replay_long {
    width: 20%;
  }
  &__error_border {
    border: 1.5px solid red;
  }
}